<template>
  <div class="Sidebar">
    <el-menu
      :default-active="activeRouter"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      background-color="#000000"
      text-color="#ffffff"
      router
      active-text-color="#F3A100"
      @select="handleSelect"
    >
      <!-- width:22px;height:22px; -->
      <el-menu-item index="/Course/Selection" popper-append-to-body>
        <svg-icon icon-class="Selection" className="testcolor" />
        <span slot="title">课程精选</span>
      </el-menu-item>
      <el-menu-item index="/Course/MyCourse/My-Course" popper-append-to-body>
        <svg-icon icon-class="MyCourse" />
        <span slot="title">我的课程</span>
      </el-menu-item>
      <el-menu-item index="/Course/Indent" popper-append-to-body>
        <svg-icon icon-class="Indent" />
        <span slot="title">课程订单</span>
      </el-menu-item>

      <div class="icon-box">
        <svg-icon v-show="!isCollapse" icon-class="fewer" style="font-size:16px;" @click="showList"/>
        <svg-icon v-show="isCollapse" icon-class="expansion" style="font-size:16px;" @click="showList"/>
      </div>
    </el-menu>

    <!-- <nav>
      <ul class="sidebar">
        <li>
          <router-link :to="'/Course/Selection'" :active-class="'active'">
            课程精选
          </router-link>
        </li>
        <li>
          <router-link :to="'/Course/MyCourse/My-Course'" :active-class="'active'">
            我的课程
          </router-link>
        </li>
      </ul>
    </nav> -->
  </div>
</template>

<script>
export default {
  name: 'SidebarName',
  data() {
    return {
      isCollapse: false,
    };
  },
  computed: {
    activeRouter: {
      set() {
        if (this.$store.getters.CouseMenuArr) {
          this.handleSelect();
        }
      },
      get() {
        return this.$store.getters.CouseMenuArr;
      },
    },
  },
  created() {
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('Coursekey', this.activeRouter);
    });
    if (!this.$store.getters.CouseMenuArr) {
      this.$store.dispatch('reSetCourseMenu', sessionStorage.getItem('Coursekey'));
    }
  },
  methods: {
    handleSelect(key) {
      this.$store.dispatch('reSetCourseMenu', key);
    },
    showList() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-box {
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 23px;
  img {
    width: 20px;
    height: 20px;
  }
}
nav {
  width: 100%;
  height: 100%;
  background-color: rgb(35, 24, 21);
  padding: 2vh 0;
  .sidebar li > a {
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: block;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  .sidebar li > a:hover {
    background: #f7b515;
  }
}
.active {
  width: 100%;
  height: 50px;
  line-height: 50px;
  display: block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background: #f7b515;
}
.el-submenu__title {
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
.el-menu-item {
  border-left: 4px solid transparent;
  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
// .testcolor {
//   fill: red;
//   color: red;
// }
</style>

<style>
.el-menu-item.is-active {
  border-left: 4px solid #F3A100;
  color: #F3A100;
  background: rgba(255, 255, 255, 0.12)!important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: calc(100vh - 62px);
}
.el-menu--collapse {
  min-height: calc(100vh - 62px);
}
</style>
