<template>
  <!--控制台：学习园地-->
  <div class="study-page">
    <!--左侧导航-->
    <side-bar />
    <!--内容-->
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import SideBar from './Sidebar';

export default {
  name: 'CourseName',
  components: {
    SideBar,
  },
};
</script>

<style lang="scss" scoped>
.study-page {
  display: flex;
  width: 100%;
  height: calc(100vh - 62px);
}
main {
  flex: 1 1 auto;
  // min-width: 1080px;
  padding: 30px 40px 20px 40px;
  overflow: auto;
}
</style>
